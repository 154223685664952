import { CircularProgress } from "@toolkit/ui";
import { FC } from "react";
import { getApolloContextFormToken } from "shared/utils";
import { useGetHealthParametersQuery } from "../../gql";
import { HealthParameterItem } from "../Reading/HealthParameterItem";
import { healthParameterCodes } from "../../constants/healthParameterCodes";

type GlucoseProps = {
  token: string;
  visitId?: string;
  patientId: string;
};
export const GlucoseView: FC<GlucoseProps> = ({ token, visitId, patientId }) => {
  const { data, loading } = useGetHealthParametersQuery({
    context: getApolloContextFormToken(token!),
    variables: {
      first: 1,
      filter: {
        code: [healthParameterCodes.bloodGlucose],
      },
    },
  });

  const healthParameter = data?.getHealthParameters?.edges?.[0]?.node;

  return loading ? (
    <CircularProgress size={50} />
  ) : (
    <HealthParameterItem
      token={token}
      width='100%'
      code={healthParameter?.code!}
      display={healthParameter?.display! || "Glucose"}
      visitId={visitId}
      unit={healthParameter?.unit?.display!}
      patientId={patientId}
      type={healthParameter?.type!}
      chartType={healthParameter?.chartType!}
    />
  );
};
