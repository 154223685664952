/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthParameterVitalSignListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HealthParameterVitalSignListQuery = { __typename?: 'Query', getHealthParameters?: { __typename?: 'HealthParameterConnection', edges?: Array<{ __typename?: 'HealthParameterEdge', node?: { __typename?: 'HealthParameter', code?: string | null, display?: string | null, arabicDisplay?: string | null, unit?: { __typename?: 'FieldUnit', display?: string | null, arabicDisplay?: string | null } | null } | null } | null> | null } | null };


export const HealthParameterVitalSignListDocument = gql`
    query HealthParameterVitalSignList {
  getHealthParameters(first: 10, filter: {categoryCode: "vital_signs"}) {
    edges {
      node {
        code
        display
        arabicDisplay
        unit {
          display
          arabicDisplay
        }
      }
    }
  }
}
    `;

/**
 * __useHealthParameterVitalSignListQuery__
 *
 * To run a query within a React component, call `useHealthParameterVitalSignListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthParameterVitalSignListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthParameterVitalSignListQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthParameterVitalSignListQuery(baseOptions?: Apollo.QueryHookOptions<HealthParameterVitalSignListQuery, HealthParameterVitalSignListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthParameterVitalSignListQuery, HealthParameterVitalSignListQueryVariables>(HealthParameterVitalSignListDocument, options);
      }
export function useHealthParameterVitalSignListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthParameterVitalSignListQuery, HealthParameterVitalSignListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthParameterVitalSignListQuery, HealthParameterVitalSignListQueryVariables>(HealthParameterVitalSignListDocument, options);
        }
export type HealthParameterVitalSignListQueryHookResult = ReturnType<typeof useHealthParameterVitalSignListQuery>;
export type HealthParameterVitalSignListLazyQueryHookResult = ReturnType<typeof useHealthParameterVitalSignListLazyQuery>;
export type HealthParameterVitalSignListQueryResult = Apollo.QueryResult<HealthParameterVitalSignListQuery, HealthParameterVitalSignListQueryVariables>;