import { useTranslation } from "@toolkit/i18n";
import { Box, CustomDialog, formatTime, getDayString, Grid, ShowButton, TextField, Typography, useModal } from "@toolkit/ui";
import React, { FC } from "react";
import { getWorkingHoursWithTimeZone } from "shared/components";
import { useGetBranchWorkingHoursQuery } from "../gql";
import { useSiteSettings } from "shared/siteSettings.var";

export const WorkingHoursBranch: FC<{ id: string }> = ({ id }) => {
  const { t, i18n } = useTranslation("provider");
  const currentDir = i18n.dir(i18n.language);
  const { handleClose, handleOpen, open: isOpen } = useModal();

  const { data } = useGetBranchWorkingHoursQuery({
    variables: {
      id: id,
    },
    skip: !isOpen,
  });
  const { timeZone } = useSiteSettings();

  const workingHours = data?.branch?.workingHours || [];

  const workingHoursData = getWorkingHoursWithTimeZone(workingHours, timeZone || 3) || [];

  return (
    <React.Fragment>
      <ShowButton onClick={handleOpen} />
      {isOpen && (
        <CustomDialog
          type='base'
          open={isOpen}
          DialogTitleProps={{
            onClose: handleClose,
            title: t("Working Hours"),
          }}
          DialogContentProps={{ sx: { minHeight: "20vh", maxHeight: "90vh" } }}
        >
          {!workingHours?.length ? (
            <Typography dir={currentDir} variant={"body1"}>
              {t("There is No Data")}
            </Typography>
          ) : (
            <Box>
              {workingHoursData?.map(workingHours =>
                workingHours?.openTimeRanges?.map((timeRange, index: number) => (
                  <React.Fragment key={workingHours?.day! + index}>
                    <Grid container alignContent={"center"} alignItems={"center"} gap={2} spacing={2} mb={1}>
                      <Grid item xs={2.5}>
                        {t(getDayString(String(workingHours?.day)))}
                      </Grid>
                      <Grid item xs={3} minWidth={120}>
                        <TextField
                          dir={currentDir}
                          size='small'
                          placeholder={t(timeRange?.openTime)}
                          label={t("Open Time")}
                          value={formatTime(timeRange?.openTime, i18n.language)}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3} minWidth={120}>
                        <TextField
                          dir={currentDir}
                          size='small'
                          placeholder={t(timeRange?.closeTime)}
                          label={t("Close Time")}
                          value={formatTime(timeRange?.closeTime, i18n.language)}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))
              )}
            </Box>
          )}
        </CustomDialog>
      )}
    </React.Fragment>
  );
};
