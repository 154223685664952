import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Grid } from "@toolkit/ui";
import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { VitalSignsCharts } from "shared/modules/patient/components/VitalSigns/components/VitalSignsCharts/VitalSignsCharts";
import { HealthParameterStethoscopeAndOtoscope } from "shared/modules/patient/components/LiveSigns/components/HealthParameterStethoscopeAndOtoscope/HealthParameterStethoscopeAndOtoscope";
import { ECGView } from "./ECGView";
import { GlucoseView } from "./GlucoseView";
import { KPIView } from "./KPIView";
import { healthParameterCodes } from "../../constants/healthParameterCodes";

type LiveSignsProps = {
  patientId: string;
};

export const LiveSigns: FC<LiveSignsProps> = props => {
  const { patientId } = props;

  const [showHistory, setShowHistory] = useState(false);

  const { t } = useTranslation("provider");
  const [params] = useSearchParams();

  const token = params.get("token")!;
  const visitId = params.get("visitId")!;
  console.log("LiveSigns -> visitId", visitId);
  const handleShowHistory = () => {
    setShowHistory(!showHistory);
  };

  return (
    <Box p={4}>
      <Grid item container spacing={2} alignContent='baseline'>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end'>
            <Button onClick={handleShowHistory}>{!showHistory ? t("Show History") : t("Last Read")}</Button>
          </Box>
        </Grid>

        {!showHistory ? <KPIView token={token} visitId={visitId} /> : <VitalSignsCharts token={token} patientId={patientId} />}

        <Grid item xs={12}>
          <ECGView token={token} patientId={patientId!} visitId={!showHistory ? visitId : undefined} />
        </Grid>

        <Grid item xs={12}>
          <GlucoseView token={token} patientId={patientId!} visitId={!showHistory ? visitId : undefined} />
        </Grid>

        <Grid item xs={12}>
          <HealthParameterStethoscopeAndOtoscope
            token={token}
            code={healthParameterCodes.stethoscopeHeartSound}
            patientId={patientId!}
            visitId={showHistory ? undefined : visitId}
          />
        </Grid>

        <Grid item xs={12}>
          <HealthParameterStethoscopeAndOtoscope
            token={token}
            code={healthParameterCodes.stethoscopeLungSound}
            patientId={patientId!}
            visitId={showHistory ? undefined : visitId}
          />
        </Grid>

        <Grid item xs={12}>
          <HealthParameterStethoscopeAndOtoscope
            token={token}
            code={healthParameterCodes.otoscopePicture}
            patientId={patientId!}
            height={450}
            visitId={showHistory ? undefined : visitId}
          />
        </Grid>

        <Grid item xs={12}>
          <HealthParameterStethoscopeAndOtoscope
            token={token}
            code={healthParameterCodes.otoscopeVideo}
            patientId={patientId!}
            height={450}
            visitId={showHistory ? undefined : visitId}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
