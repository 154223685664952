import { useHealthParameterVitalSignListQuery } from "../../../../shared/modules/patient/gql";
import { getApolloContextFormToken } from "../../../../shared/utils";
import { HealthParameter, PatientHealthParameter } from "@health/queries/types";
import { formatPatientHealthParametersVitalSigns } from "../others";
import { useVisitPatientHealthParameterListQuery } from "../gql";

type HealthParameterVitalSignsProps = {
  visitId: string;
  token?: string;
};

export const useHealthParameterVitalSigns = (props: HealthParameterVitalSignsProps) => {
  const { visitId, token } = props;

  const { data: healthParameterVitalSignListData, loading: isVisitHealthParameterListLoading } = useHealthParameterVitalSignListQuery({
    fetchPolicy: "no-cache",
    context: getApolloContextFormToken(token!),
  });

  const healthParametersVitalSigns = (healthParameterVitalSignListData?.getHealthParameters?.edges?.map(item => item?.node) ||
    []) as HealthParameter[];

  const {
    data: visitPatientHealthParameterListData,
    loading: isVisitPatientHealthParameterListLoading,
    refetch,
  } = useVisitPatientHealthParameterListQuery({
    variables: { visitId },
    fetchPolicy: "no-cache",
    skip: !visitId,
    context: getApolloContextFormToken(token!),
  });

  const visitVitalSigns = (visitPatientHealthParameterListData?.visit?.vitalSigns || []) as PatientHealthParameter[];

  const formattedVisitVitalSigns = formatPatientHealthParametersVitalSigns(healthParametersVitalSigns, visitVitalSigns);

  return {
    data: formattedVisitVitalSigns,
    isLoading: isVisitHealthParameterListLoading || isVisitPatientHealthParameterListLoading,
    refetch,
  };
};
