import { TableGrid } from "@toolkit/ui";
import { FC } from "react";
import { GridProvider } from "shared/components";
import { getApolloContextFormToken } from "shared/utils";
import { usePatientHealthParametersQuery } from "../../gql";
import { useVitalSignsColumns } from "./useVitalSignsColumns";
import { H_OrderDirection, PatientHealthParameter, PatientHealthParameterSortField } from "@health/queries/types";
import { healthParameterCodes } from "../../constants/healthParameterCodes";

type VitalSignsTableProps = {
  patientId: string;
  token: string;
  codes: string[];
  visitId?: string;
};

export const VitalSignsTable: FC<VitalSignsTableProps> = props => {
  const { patientId, visitId, codes, token } = props;

  const bloodPressureCodes = codes[0] === healthParameterCodes.bloodPressureHigh || codes[1] === healthParameterCodes.bloodPressureLow;
  return (
    <GridProvider
      isCardView
      gridName='patientHealthParameters'
      columns={useVitalSignsColumns()}
      query={usePatientHealthParametersQuery}
      context={getApolloContextFormToken(token!)}
      variables={{
        first: bloodPressureCodes ? 20 : 10,
        filter: {
          visitId,
          patientId: [patientId],
          codes: codes,
        },
        sortBy: {
          field: PatientHealthParameterSortField.CreatedDate,
          direction: H_OrderDirection.Desc,
        },
      }}
      extractCountableConnectionData={({ responseData }) => {
        if (bloodPressureCodes) {
          const combinedData =
            responseData?.patientHealthParameters?.edges?.reduce((acc, curr) => {
              const { createdDate, valueNumber } = curr.node;
              if (acc[createdDate]) {
                acc[createdDate].valueNumber = `${valueNumber}/${acc[createdDate].valueNumber}`;
              } else {
                acc[createdDate] = { ...curr.node, valueNumber };
              }
              return acc;
            }, {}) || [];

          const result: PatientHealthParameter[] = Object.values(combinedData);

          return {
            totalCount: null,
            pageInfo: responseData?.patientHealthParameters?.pageInfo,
            nodes: result || [],
          };
        }

        return {
          totalCount: null,
          pageInfo: responseData?.patientHealthParameters?.pageInfo,
          nodes: responseData?.patientHealthParameters?.edges?.map(item => item?.node) || [],
        };
      }}
    >
      <TableGrid />
    </GridProvider>
  );
};
