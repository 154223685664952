/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type AppointmentFragmentFragment = { __typename?: 'Appointment', id: string, created?: any | null, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null, requestedBy?: Types.RequestedBy | null, requestedTimeSlots?: Array<string | null> | null, suggestedTimeSlots?: Array<string | null> | null, confirmedStartTime?: any | null, confirmedEndTime?: any | null, cancellationReason?: string | null, paymentStatus?: Types.PaymentStatus | null, doctor?: { __typename?: 'Doctor', id: string, appointmentSlotTimePeriod: number, user?: { __typename?: 'User', fullName?: string | null } | null } | null, consumer?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null, user?: { __typename?: 'User', id: string } | null, activeHealthProgramMembers?: Array<{ __typename?: 'HealthProgramMember', id: string, insuranceId?: string | null, membershipEnd?: any | null, payer?: { __typename?: 'Payer', name: string } | null } | null> | null, healthProgramsByActiveMembers?: Array<{ __typename?: 'HealthProgram', insuranceNetworks?: { __typename?: 'InsuranceNetworkCountableConnection', edges: Array<{ __typename?: 'InsuranceNetworkCountableEdge', node: { __typename?: 'InsuranceNetwork', id: string, name?: string | null } }> } | null } | null> | null } | null, branch?: { __typename?: 'Branch', id: string, name: string } | null, visit?: { __typename?: 'Visit', id: string, number?: string | null, meetingPlatformLinkForJoinCall?: string | null, meetingPlatformLinkForChatView?: string | null, patient?: { __typename?: 'Patient', id: string, number?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null } | null } | null, paymentInfo?: { __typename?: 'AppointmentPaymentRequest', totalAmount?: number | null, type?: Types.AppointmentPaymentType | null } | null };

export const AppointmentFragmentFragmentDoc = gql`
    fragment AppointmentFragment on Appointment {
  id
  created
  type
  status
  requestedBy
  requestedTimeSlots
  suggestedTimeSlots
  confirmedStartTime
  confirmedEndTime
  cancellationReason @include(if: $includeCancellationReason)
  doctor {
    id
    appointmentSlotTimePeriod
    user {
      fullName
    }
  }
  consumer {
    id
    firstName
    lastName
    user {
      id
    }
    activeHealthProgramMembers {
      id
      insuranceId
      payer {
        name
      }
      membershipEnd
    }
    healthProgramsByActiveMembers {
      insuranceNetworks(first: 1) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
  branch {
    id
    name
  }
  visit {
    id
    number
    meetingPlatformLinkForJoinCall
    meetingPlatformLinkForChatView
    patient {
      id
      number
      firstName
      lastName
      email
    }
  }
  paymentInfo {
    totalAmount
    type
  }
  paymentStatus
}
    `;