import { HealthParameter, PatientHealthParameter, PatientHealthParameterInput, VisitStatus, VisitType } from "@health/queries/types";
import { capitalize } from "@toolkit/core";
import { i18n, pickLocalizedValue } from "@toolkit/i18n";
import { IVitalSignsUpdateFormValues } from "pages/e-visit/VitalSigns/forms/VitalSignUpdate/VitalSignUpdateFormSchema";
import { healthParameterCodes } from "../../../../shared/modules/patient/constants/healthParameterCodes";
import { sourceOptionsMap } from "@health/enum-options";
import { isNil } from "lodash";

export const isVisitDetailsEnabled = (status: VisitStatus) => {
  return (
    status === VisitStatus.Completed ||
    status === VisitStatus.CallInProgress ||
    status === VisitStatus.Incompleted ||
    status === VisitStatus.CallRejoinable
  );
};

export const getVisitStatus = (status?: VisitStatus | null) => {
  if (!status) return;

  return capitalize(status.toLowerCase().split("_"));
};

export const getCareType = (type?: VisitType | null) => {
  if (!type) return;

  return capitalize(type.toLowerCase().split("_"));
};

export const formatPatientHealthParametersVitalSigns = (
  healthParameters: HealthParameter[],
  patientHealthParameters?: PatientHealthParameter[]
) => {
  const healthParametersVitalSigns =
    healthParameters
      ?.filter(item => ![healthParameterCodes.bloodPressureLow, healthParameterCodes.bloodPressureHigh].includes(item?.code!))
      ?.map(item => {
        const patientHealthParameter = patientHealthParameters?.find(param => param?.hpCode === item?.code);

        return {
          code: item?.code!,
          display: pickLocalizedValue(item?.display!, item?.arabicDisplay!),
          unit: pickLocalizedValue(item?.unit?.display, item?.unit?.arabicDisplay),
          value: !isNil(patientHealthParameter?.valueNumber) ? parseFloat(patientHealthParameter?.valueNumber!) : null,
          source: patientHealthParameter ? sourceOptionsMap[patientHealthParameter?.source!]?.label : null,
        };
      })
      ?.sort((a, b) => a?.display.localeCompare(b?.display)) || [];

  const bloodPressureLow = healthParameters?.find(item => item?.code === healthParameterCodes.bloodPressureLow);
  const bloodPressureHigh = healthParameters?.find(item => item?.code === healthParameterCodes.bloodPressureHigh);

  const bloodPressureLowValue = patientHealthParameters?.find(
    param => param?.hpCode === healthParameterCodes.bloodPressureLow
  )?.valueNumber;
  const bloodPressureHighValue = patientHealthParameters?.find(
    param => param?.hpCode === healthParameterCodes.bloodPressureHigh
  )?.valueNumber;

  if (bloodPressureLow && bloodPressureHigh) {
    const bloodPressure = {
      code: `${bloodPressureHigh?.code}/${bloodPressureLow?.code}`,
      display: i18n.t("Blood Pressure", { ns: "provider" }),
      unit: pickLocalizedValue(bloodPressureHigh.unit?.display!, bloodPressureHigh.unit?.arabicDisplay!),
      value: !isNil(bloodPressureHighValue && bloodPressureLowValue)
        ? `${parseFloat(bloodPressureHighValue)}/${parseFloat(bloodPressureLowValue)}`
        : undefined,
      source:
        sourceOptionsMap[patientHealthParameters?.find(param => param?.hpCode === healthParameterCodes.bloodPressureHigh)?.source!]?.label,
    };
    return [bloodPressure, ...healthParametersVitalSigns];
  }

  return healthParametersVitalSigns;
};

export const convertVisitVitalSignFormValuesToBackEndValues = (values: IVitalSignsUpdateFormValues): PatientHealthParameterInput[] => {
  const patientHealthParameters =
    values?.items
      ?.filter(item => item?.value && item?.code !== `${healthParameterCodes?.bloodPressureHigh}/${healthParameterCodes?.bloodPressureLow}`)
      ?.map(item => ({
        hpCode: item?.code!,
        valueNumber: item?.value,
      })) || [];

  const bloodPressure = values?.items?.find(
    item => item?.code === `${healthParameterCodes?.bloodPressureHigh}/${healthParameterCodes?.bloodPressureLow}`
  );

  const patientHealthParametersBloodPressures: PatientHealthParameterInput[] = [];

  if (bloodPressure?.value) {
    const [highValue, lowValue] = (bloodPressure.value as string).split("/");

    if (highValue && lowValue) {
      patientHealthParametersBloodPressures.push(
        {
          hpCode: healthParameterCodes?.bloodPressureHigh,
          valueNumber: Number(highValue),
        },
        {
          hpCode: healthParameterCodes?.bloodPressureLow,
          valueNumber: Number(lowValue),
        }
      );
    }
  }

  return [...patientHealthParameters, ...patientHealthParametersBloodPressures];
};
