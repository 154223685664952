import { useVisitsVitalSignsChanged } from "@health/common";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, FormCard, FormNumberField, FormTextField, Grid } from "@toolkit/ui";
import { IVitalSignUpdateFormEvent } from "pages/e-visit/VitalSigns/types";
import React, { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { healthParameterCodes } from "../../../../../shared/modules/patient/constants/healthParameterCodes";
import { IVitalSignsUpdateFormValues, vitalSignUpdateFormSchema, vitalSignsUpdateFormDefaultValues } from "./VitalSignUpdateFormSchema";
import { useHealthParameterVitalSigns } from "../../hooks/useHealthParameterVitalSigns";

type VitalSignsUpdateFormProps = {
  visitId: string;
  token?: string;
  isSubmitting?: boolean;
  onChange: (event: IVitalSignUpdateFormEvent) => void;
};

export const VitalSignUpdateForm: FC<VitalSignsUpdateFormProps> = props => {
  const { visitId, token, isSubmitting, onChange } = props;

  const { t } = useTranslation("provider");

  const { data, isLoading, refetch } = useHealthParameterVitalSigns({
    visitId,
    token,
  });

  const form = useCustomForm<IVitalSignsUpdateFormValues>({
    defaultValues: vitalSignsUpdateFormDefaultValues,
    schema: vitalSignUpdateFormSchema,
  });

  const { watch, setValue, handleSubmit } = form;

  const items = watch("items");

  const onSubmit = (values: IVitalSignsUpdateFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useVisitsVitalSignsChanged(({ data: visitsVitalSignData }) => {
    if (visitsVitalSignData?.patientId) {
      refetch({ visitId });
    }
  });

  useEffect(() => {
    if (data?.length) {
      const _data = data?.map(item => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { source, ...rest } = item;
        return rest;
      });

      setValue("items", _data);
    }
  }, [data?.length, setValue]);

  return (
    <Grid container spacing={2}>
      {isLoading ? (
        <Grid item xs={12}>
          <Box textAlign={"center"}>
            <CircularProgress size={30} />
          </Box>
        </Grid>
      ) : (
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
              <FormCard loading={false} doYouHaveData>
                <Grid container spacing={1}>
                  {items?.map((item, index) => {
                    if (item?.code === `${healthParameterCodes?.bloodPressureHigh}/${healthParameterCodes?.bloodPressureLow}`) {
                      return (
                        <Grid key={item?.code} item xs={6}>
                          <FormTextField
                            name={`items.${index}.value`}
                            label={item?.display}
                            placeholder={item?.display}
                            InputProps={{ endAdornment: item?.unit }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      );
                    }

                    return (
                      <Grid key={item?.code} item xs={6}>
                        <FormNumberField
                          name={`items.${index}.value`}
                          label={item?.display}
                          placeholder={item?.display}
                          InputProps={{ endAdornment: item?.unit }}
                          InputLabelProps={{ shrink: true }}
                          isFloatAllowed={
                            item?.code === healthParameterCodes?.oxygenSaturation || item?.code === healthParameterCodes?.temperature
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </FormCard>
            </Grid>

            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
              <Box mx={2}>
                <Button
                  type={"submit"}
                  disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress color={"inherit"} size={20} /> : null}
                >
                  {t("Update")}
                </Button>
              </Box>
            </Grid>
          </form>
        </FormProvider>
      )}
    </Grid>
  );
};
