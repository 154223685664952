import { useVisitsVitalSignsChanged } from "@health/common";
import { KPICard } from "./KPICard";
import { Box, CircularProgress, Grid } from "@toolkit/ui";
import { FC } from "react";
import { useHealthParameterVitalSigns } from "../../../../../pages/e-visit/VitalSigns/hooks/useHealthParameterVitalSigns";

type KPIViewProps = {
  token?: string;
  visitId: string;
};

export const KPIView: FC<KPIViewProps> = props => {
  const { token, visitId } = props;

  const { data, isLoading, refetch } = useHealthParameterVitalSigns({
    visitId,
    token,
  });

  useVisitsVitalSignsChanged(({ data: visitsVitalSignsChangedData }) => {
    if (visitsVitalSignsChangedData?.patientId) {
      refetch({ visitId });
    }
  });

  return isLoading ? (
    <Box display={"flex"} justifyContent={"center"} m={1}>
      <CircularProgress size={50} />
    </Box>
  ) : (
    <Grid item container spacing={2} alignContent={"baseline"}>
      {data?.map(item => {
        return (
          <Grid key={item?.code} item xs={12} sm={4} md={4} lg={2.4}>
            <KPICard title={item?.display} value={item?.value!} unit={item?.unit!} description={item?.source!} />
          </Grid>
        );
      })}
    </Grid>
  );
};
